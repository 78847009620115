.App {
  text-align: center;
}

.test2 {
  border: 2px solid red;
}

.og-form {
  width: 400px;
  margin: auto;
}
.og-form h1 {
  color: rgb(65, 65, 65);
}

.og-form button {
  margin: auto;
  margin-right: 8px;
  margin-top: 10px;
}

.license_table:hover {
  color: #3f51b5;
  font-weight: 600;
}

.customer_title {
  position: absolute;
  top: -47px;
  left: 80px;
  color: white;
  z-index: 1100;
}

.partner_title {
  position: absolute;
  top: 15px;
  left: 80px;
  color: white;
  z-index: 1100;
}

a {
  text-decoration: none;
  color: inherit;
}

tr:hover {
  cursor: pointer;
  color: #3f51b5;
  font-weight: 700;
}
th:hover {
  cursor: auto;
}

.table-input input {
  padding: 8px 5px;
  font-size: 12px;
}

thead:first-child th {
  color: #3a3a3a;
  font-weight: 600;
}

/*tbody tr:nth-child(even) {
  background-color: #ebebeb;
} */

/*table header sort buttons*/
th span svg {
  vertical-align: middle;
  display: table-cell;
  color: #3f51b5;
}

.test {
  border: 2px solid red;
}

.license-dialog-content {
  display: flex;
  flex-direction: column;
}

.og-form_lg {
  padding: 0px;
  color: red;
  border: 10px solid red;
}

.add-license_row {
  display: flex;
}

/*
REACT-FINAL FORMS STYLES
.og-form{
  width: 500px;
  padding: 40px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background-color: #ffffff;
  border-radius: 3px;
}

.og-form_item{
  display: flex;
  flex-direction: column;
  width: 90%;
}

.og-form_label_hdr{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 1em;
}

.og-form_label{
  color: black;
}
.og-form_error{
  color: #e81b16;
}
.og-form_error-msg{
  padding-top: 5px;
  color: #f65855;
  font-weight: 500;
  font-size: 0.9em;
  font-family: serif;
  letter-spacing: 0.02em;
  text-align: left;
}

.og-form_input{
  font-family: Arial, Helvetica, sans-serif;
  background-color: #e4e3e9;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  color: black;
  font-size: 16px;
  letter-spacing: 0.02em;
}

.og-form_btn{
  border: 2px solid #1e69ff;
  color: #1e69ff;
  border-radius: 5px;
  font-size: 1.05em;
  font-weight: 600;
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.05s linear;
}
.og-form_btn:hover{
  background-color: #1e69ff;
  color: white;
  transition: all 0.05s linear;
}

.og-form_btn--error{
  border: 2px solid #f65855;
  color: #f65855;
  border-radius: 5px;
  font-size: 1.05em;
  padding: 10px 10px;
  cursor: pointer;
}
.og-form_btn--error:hover{
  background-color: #f65855;
  color: white;
}




/*
-----------------------------
DARK

color-background-body: #000000;
color-background-base: #18171d; 
color-background-alt: #222127;
color-background-alt-2: #2f2f37;
color-background-accent: #782ce8;
color-background-accent-alt: #5c16c6;
color-background-alt-2: #451094;

input-background: #4d4d4d;

color-fill-brand: #9246ff;
color-fill-info: #1e69ff;
color-fill-warn: #ffca60;
color-fill-alert: #e81b16;
color-fill-success: #00e4c9;

-----------------------------
LIGHT

color-background-body: #fafafa;
color-background-base: #ffffff;
color-background-alt: #efeff1
color-background-alt-2: #e4e3e9;
color-background-accent: #782ce8;
color-background-accent-alt: #5c16c6;
color-background-alt-2: #451094;

color-fill-brand: #9246ff;
color-fill-info: #1e69ff;
color-fill-warn: #ffca60;
color-fill-alert: #e81b16;
color-fill-success: #00e4c9;

*/
